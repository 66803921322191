/* eslint-disable max-len */
import React from "react";
import Footer from "../components/Home/internal/Footer";
import Layout from "../components/Layout";
import styles from "../styles/Legal.module.scss";

const PrivacyPolicy = () => (
  <Layout hideFooter whiteBackground title="Bidddy | Privacy Policy">
    <div className={styles.backgroundContainer}>
      <h2>Privacy Policy</h2>
      <div
        className={styles.termsContainer}
        style={{ color: "black", background: "white" }}
      >
        <p>
          <i>Updated August 5th, 2021</i>
        </p>
        <div>
          <br />
          <h1>What does this Privacy Policy Cover?</h1>
          <p>This Privacy Policy applies to information we collect:</p>
          <p>
            • In email, text and other electronic messages between you and us.
            Through the Website and Services (including registration and contact
            forms, as well as usage data)
          </p>
          <p>
            This Privacy Policy covers our treatment of personally identifiable
            information. This is information which may be connected to you
            specifically as the individual to whom the information relates. Such
            information may include name, mailing address, email address,
            telephone number, or any other information defined as personally
            identifiable information (or similar term) by applicable laws
            (collectively, “Personal Information”). Personal Information does
            not include your personally identifiable information that has been
            deidentified, pseudonymized, anonymized, aggregated and/or otherwise
            processed so as to be unidentifiable (i) in such a way that the data
            can no longer be attributed to a specific individual, or (ii) in
            such a way that the data can no longer be attributed to a specific
            individual (by reasonable means) without the use of additional
            information, and where such additional information is kept separate
            and under adequate security to prevent unauthorized
            re-identification of a specific individual such that one could not,
            using reasonable efforts, link such information back to a specific
            individual (the foregoing being referred to as “De-Identified
            Personal Information”).
          </p>
          <br />
          <h1>Information We Collect About You and How We Collect It</h1>
          <p>
            We collect several types of information from and about users,
            including Personal Information that is about you but does not
            identify you, such as, time and date of visits to our Website, etc.;
            and/or, about your internet connection, the equipment you use to
            access our Website and usage details.
          </p>
          <p>We collect this information:</p>
          <p>
            • Directly from you when you provide it to us. When you sign up for
            the Services, you provide us with information such as your name and
            email address. We may also collect information from your public
            social media accounts, automatically as you navigate through the
            Website or use the Services. Information collected automatically may
            include usage details, IP addresses and information collected
            through cookies, web beacons and other tracking technologies.
          </p>
          <p>
            The information we collect on or through our Website may include:
          </p>
          <br />‍
          <br />
          <p>
            • Information that you provide by filling in forms on our Website.
            This includes information provided at the time of registering to use
            our Website, subscribing to or purchasing our services, posting
            material or requesting further services. We may also ask you for
            information when you report a problem with our Website or the
            Service.
          </p>
          <p>
            • Records and copies of your correspondence (including email
            addresses), if you contact us.• Your responses to surveys that we
            might ask you to complete for research purposes.
          </p>
          <p>
            • Your responses to surveys that we might ask you to complete for
            research purposes.
          </p>
          <p>• Details of your activity on the Website or Service.</p>
          <p>
            • Details of your visits to our Website, including, but not limited
            to, traffic data, geolocation data, logs and other communication
            data and the resources that you access and use on the Website.
          </p>
          <p>
            • Information about your computer and internet connection, including
            your IP address, operating system and browser type.
          </p>
          <br />
          <p>
            <h1>How We Use Your Information</h1>
          </p>
          <p>
            We will use the Personal Information you provide us only in
            accordance with this Privacy Policy, and applicable laws. We will
            use this information to:
          </p>
          <p>
            • Operate, maintain, and provide to you the features and
            functionality of the Service. Communicate with you about the
            Service, including to provide you with notices about your account,
            including expiration and renewal notices. Improve the Service or our
            other offerings. Present our Website and its contents to you.
            Provide you with information, products or services that you request
            from us. Fulfill any other purpose for which you provide it. Carry
            out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection. Notify you about changes to our Website, our policies,
            terms or any products or services we offer or provide though it.
            Allow you to participate in interactive features on our Website.
          </p>
          <p>
            By providing us your email address you consent to our using that
            email address to send you Website and/or Service-related notices,
            including any notices required by law, in lieu of communication by
            postal mail. You also agree that we may send you notifications of
            activity on our Website to the email address you give us, in
            accordance with any applicable privacy settings. We may use your
            email address to send you other messages, such as newsletters,
            changes to features of our Website, or other information. If you do
            not want to receive such email messages, you may opt out using the
            opt out link in the footer of any email from us.
          </p>
          <p>
            We use cookies, clear gifs, and log file information to: (a)
            remember information so that you will not have to re-enter it during
            your visit or the next time you visit the site; (b) provide custom,
            personalized content and information; (c) monitor the effectiveness
            of our Service; (d) monitor aggregate metrics such as total number
            of visitors, traffic, and demographic patterns; (e) diagnose or fix
            technology problems reported by our users or engineers that are
            associated with certain IP addresses; (f) help you efficiently
            access your information after you sign in; (h) track User Content
            and users to the extent necessary to comply as a service provider
            with the Digital Millennium Copyright Act; and (i) enhance our
            Website security.
          </p>
          <br />
          <h1>Retention of Your Information</h1>
          <p>
            Following termination or deactivation of your account, we may retain
            your Personal Information and other data, but will maintain it as
            confidential according to this Privacy Policy and as required by
            applicable law. We have the right to delete all of your Personal
            Information and other data after termination of your account without
            notice to you.
          </p>
          <p>
            You are able to request your data to be deleted by emailing{" "}
            <a href="mailto:privacy@bidddy.com">privacy@bidddy.com</a> with the
            subject line &quot;Data Deletion&quot;.
          </p>
          <br />
          <h1>How We Share Your Information</h1>
          <p>
            We will never rent or sell your Personal Information to others. We
            may share your Personal Information with third parties for the
            purpose of providing or improving the Services. If we do this, such
            third parties’ use of your Personal Information will be bound by
            obligations of confidentiality. We may store Personal Information in
            locations outside our direct control (for instance, on servers or
            databases located or co-located with hosting providers).
          </p>
          <p>
            Except as otherwise described in this Privacy Policy, we will not
            disclose Personal Information to any third party unless required to
            do so by law, court order, legal process, or subpoena, including to
            respond to any government or regulatory request, or if we believe
            that such action is necessary to (a) conform to the law, comply with
            legal process served on us or our affiliates or partners, or
            investigate, prevent, or take action regarding suspected or actual
            illegal activities; and (b) to enforce our terms of use or any
            agreement with you, take precautions against liability, to
            investigate and defend ourselves against any third-party claims or
            allegations, to assist government enforcement agencies, or to
            protect the security or integrity of our site.
          </p>
          <p>
            We may share De-Identified Personal Information (such as anonymous
            usage data, referring/exit pages and URLs, platform types, number of
            clicks, etc.) with interested third parties to help them understand
            the usage patterns for certain Services
          </p>
          <br />
          <h1>
            Your Consent to Our Disclosure/Assignment of Your Personal
            Information
          </h1>
          <p>
            You consent to our disclosure of your Personal Information and other
            information to a potential buyer or other successor for the purpose
            of considering a merger, divestiture, restructuring, reorganization,
            dissolution, or sale or transfer of some or all of our assets,
            whether as a going concern or as part of bankruptcy, liquidation or
            similar proceeding, in which Personal Information held by us about
            our Website users is among the assets transferred. You agree to and
            do hereby consent to our assignment, conveyance, or transfer
            (whether by contract, merger or operation of law) of rights to your
            Personal Information and other information, with or without notice
            to you and without your further consent.
          </p>
          <br />
          <h1>Security</h1>
          <p>
            We take security seriously, and take robust commercially reasonable
            measures to protect your personal information from loss or theft, as
            well as unauthorized access, disclosure, copying, use or
            modification. However, we advise that no computer system or network
            can ever be 100% secure, and therefore we cannot guarantee the
            absolute security of your personal information. Any transmission of
            Personal Information is at your own risk. Unauthorized entry or use,
            hardware or software failure, and other factors, may compromise the
            security of user information at any time. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on the Website or used with our Services.
          </p>
          <p>
            The safety and security of your information also depends on you.
            Where we have given you (or where you have chosen) a password for
            access to certain parts of the Website or Services, you are
            responsible for keeping this password confidential. You should not
            share your password with anyone. You must prevent unauthorized
            access to your account and Personal Information by selecting and
            protecting your password appropriately and limiting access to your
            computer or device and browser by signing off after you have
            finished accessing your account. The information you share in public
            areas may be viewed by other users.
          </p>
          <br />
          <h1>Notices; Opting Out</h1>
          <p>
            All notices – whether required by law or otherwise – will be sent to
            the email address used to register for the Services. You consent to
            receive such notices by email, and not by post. If you choose not
            receive these emails by “opting out”, you acknowledge that you not
            not receive important legal notices, and hereby waive any claims you
            may have against Bidddy on account of not receiving such notices.
          </p>
          <br />
          <p>
            <h1>Individuals under the Age of 18</h1>
          </p>
          <p>
            We do not knowingly collect, solicit or maintain Personal
            Information from anyone under the age of 18, or knowingly allow such
            persons to register for our Services, with the exception of children
            13 or older who have permission from a parent or guardian who has
            entered into an agreement with Bidddy on the child&apos;s behalf. If
            you are under 18, please do not send any Personal Information about
            yourself (such as your name, address, telephone number, or email
            address) to us. No one under age 18 is allowed to provide any
            Personal Information to or on the Services. In the event that we
            learn that we have collected Personal Information from a child under
            age 18 without verification of parental consent, we will use
            commercially reasonable efforts to delete that information from our
            database. Please contact us at privacy@bidddy.com with the subject
            line &quot;Privacy&quot; if you have any concerns.
          </p>
          <br />
          <h1>Changes to Our Privacy Policy</h1>
          <p>
            We are constantly working to improve our Services, so we may need to
            change this Privacy Policy from time to time as well, but we will
            alert you to changes by placing a notice on our Website or as part
            of the Services, by sending you an email, and/or by some other
            means. Please note that if you’ve opted not to receive legal notice
            emails from us (or you haven’t provided us with your email address),
            those legal notices will still govern your use of the Services, and
            you are still responsible for reading and understanding them. If you
            use the Services after any changes to the Privacy Policy have been
            posted, that means you agree to all of the changes. Use of
            information we collect now is subject to the Privacy Policy in
            effect at the time such information is collected.
          </p>
          <p>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this website, please contact us
            at privacy@bidddy.com with the subject line &quot;Privacy&quot;, or
            send mail to:
          </p>
          <p>Bidddy Digital Inc.</p>
          <p>ATTN: PRIVACY DEPARTMENT</p>
          <p>
            12974 19 Avenue.
            <br />
            Surrey, BC
            <br />
            V4A 8P2
          </p>
          <p>‍</p>
          <p>‍</p>
          <p>
            <i>Updated: February 7, 2022</i>
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
);

export default PrivacyPolicy;
